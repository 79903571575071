import { TrackingProps } from 'contexts/trackingContext'
import React, { useContext, useEffect, useMemo } from 'react'
import HighIntentRecommendationTile, { HighIntentTileProps } from 'components/OfferList/HighIntent/HighIntentRecommendationTile'
import getCheckInOutText from 'components/Recommendations/utils/getCheckInOutText'
import { getBnblOccupancyText, getOccupancyText } from 'components/Recommendations/utils/getOccupancyText'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import GeoContext from 'contexts/geoContext'
import moment from 'moment'
import HighIntentRecommendationTileLoader from './HighIntentRecommendationTileLoader'
import { useAbandonedCartData } from 'hooks/Recommendation/useAbandonedCartData'
import { isCruiseV1 } from 'components/Common/StickyPromptCard/useAbandonedCartSelectionLogic'
import { getTimeLeftUrgencyLabels } from 'lib/offer/highIntentOffers'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import offerPageURL from 'lib/offer/offerPageURL'
import { HighIntentOfferDisplayStatus } from 'home/components/OfferCarousels/HighIntentOfferCard'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { OFFER_TYPE_HOTEL } from 'constants/offer'

interface Props {
  highIntentOffer: App.AbandonedCartRecommendation;
  position: number;
  tracking?: TrackingProps;
  onClick?: HighIntentTileProps['onClick'];
  onClose?: HighIntentTileProps['onClose'];
  className?: string;
  onDisplayStatusChange?: (status: HighIntentOfferDisplayStatus) => void;
}

const tileTestId = 'abandoned-cart-tile'

function AbandonedCartTile(props: Props) {
  const { highIntentOffer, position, tracking, onClick, onDisplayStatusChange, onClose, className } = props
  const { abandonedCartOfferView, totalsView, isCartDataReady } = useAbandonedCartData(highIntentOffer)
  const offer = abandonedCartOfferView?.offer
  const { currentRegionCode } = useContext(GeoContext)
  const category = offer?.analyticsType ?? 'unknown'

  const isFlashOffer = offer?.type === OFFER_TYPE_HOTEL
  const countdownLabelVariant = useOptimizelyExperiment(OptimizelyExperiments.croShowUrgencyLabelsAt7Days, isFlashOffer)

  const updatedTracking = useMemo(() => (tracking ? {
    ...tracking,
    category,
    lere: {
      version: highIntentOffer.lereVersion,
    },
  } : undefined), [tracking, category, highIntentOffer])

  useEffect(() => {
    if (!isCartDataReady) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.LOADING)
    } else if (!abandonedCartOfferView || !totalsView || !offer) {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.HIDE)
    } else {
      onDisplayStatusChange?.(HighIntentOfferDisplayStatus.SHOW)
    }
  }, [isCartDataReady, abandonedCartOfferView, totalsView, offer, onDisplayStatusChange])

  if (!isCartDataReady) {
    return <HighIntentRecommendationTileLoader />
  }

  if (!abandonedCartOfferView || !totalsView || !offer) {
    return null
  }

  const {
    occupancy,
    image,
    mainLabel: title,
    startDate,
    endDate,
    duration,
    durationLabel,
    reservationType,
    itemViews,
  } = abandonedCartOfferView

  const filters = {
    checkIn: startDate,
    checkOut: endDate,
    rooms: occupancy,
  }
  const queryParams = buildSearchParamsFromFilters(filters)
  const offerPageUrl = offerPageURL(offer, queryParams)

  const checkInMoment = startDate ? moment(startDate) : undefined
  const checkOutMoment = endDate ? moment(endDate) : undefined

  const checkInOutText = getCheckInOutText(checkInMoment, checkOutMoment)

  const saleUnit = isCruiseV1(abandonedCartOfferView) ? undefined : abandonedCartOfferView.saleUnit
  const occupancyText = reservationType === 'buy_now_book_later' ?
    getBnblOccupancyText(itemViews.length, saleUnit) :
    getOccupancyText(occupancy, saleUnit)

  const urgencyLabels = getTimeLeftUrgencyLabels(offer, currentRegionCode, countdownLabelVariant)

  const durationText = `${durationLabel} from`

  const { savedPercentage: discountPercent, grandTotal, creditPaymentAmount, isMemberPrice } = totalsView
  const discountPercentage = (discountPercent && (discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE)) ? discountPercent : undefined
  // grandTotal may include offset from credit, so we have to add it back
  const displayedPrice = grandTotal + (creditPaymentAmount ?? 0)

  const tileProperties: HighIntentTileProps = {
    offerId: highIntentOffer.offerId,
    highIntentCategory: highIntentOffer.category,
    url: offerPageUrl,
    title,
    image,
    checkInOutText,
    occupancyText,
    urgencyLabels,
    durationText,
    duration,
    displayedPrice,
    isMemberPrice,
    discountPercentage,
    position,
    tracking: updatedTracking,
    testId: tileTestId,
    onClick,
    onClose,
    className,
  }

  return (
    <HighIntentRecommendationTile {...tileProperties} />
  )
}

export default React.memo(AbandonedCartTile)
